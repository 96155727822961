/* fullfile added by dev-Ayush 28-02-2025*/

.nextButton {
  margin-top: 16px !important;
  background-color: #F3D45E !important;
  color: #000 !important;
  width: 400px !important;
  border-radius: 24px !important;
  height: 52px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.container {
  width: 100% !important;
  /* display: flex !important; */
  /* flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important; */
  height: 100vh !important;
  /* background-color: #121826; */
  color: #fff;
  border-radius: 10px;
  /* height: auto; */
  padding: 0px !important;
  position: relative;
  /* overflow-y: auto !important; */
}

.flexColumnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.textField {
  width: 400px !important;
  height: 42px;
  background-color: white;
  border-radius: 12px;
  border: none;
  padding: 10px;
  outline: none;
  /* Removes focus outline */
  font-size: 16px;
  color: #000;
  /* Ensures input text is visible */
}

.textField:focus {
  border: none;
  outline: none;
  /* Ensures no outline appears on focus */
}

.subscribeButton {
  width: 352px;
  height: 52px;
  border-radius: 32px !important;
  border: 1.5px solid #B6979133;
  text-transform: capitalize !important;
  font-size: 15px;
  margin-top: 24px !important;
}

.subscribeButton:hover {
  background-color: #C49D09 !important;
}

.apipopUp {
  background-size: cover;
  background-repeat: no-repeat !important;
  width: 1000px;
  height: 150px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.pricingBox {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 22px !important;
  column-gap: 24px !important
}

.createApplication {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  margin-top: 22px !important;
  column-gap: 24px !important;
}



.priceBox {
  width: 400px;
  height: 248px;
  border-radius: 16px;
  padding: 24px;
}

.priceBoxTitle {
  font-size: 48px !important;
  color: #E4DAD7 !important;
}

.subscribeButton {
  border: 1.5px solid #B6979133 !important;
  color: #E4DAD7 !important;
  background-color: transparent !important;
  cursor: pointer !important;

}

.generateButton {
  display: flex !important;
  justify-content: end !important;
  width: 740px !important;

}

.customscrollbar {
  overflow-x: auto;
}

/* Scrollbar width */
.customscrollbar::-webkit-scrollbar {
  height: 8px;
  /* Horizontal scrollbar height */
}

/* Scrollbar track */
.customscrollbar::-webkit-scrollbar-track {
  background: #222;
  /* Background color */
  border-radius: 10px;
}

/* Scrollbar thumb */
.customscrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Scrollbar color */
  border-radius: 10px;
}

/* Scrollbar thumb on hover */
.customscrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker on hover */
}

.box1 {
  width: 1200px;
  padding-right: 20px
}

.box2 {
  /* display : flex; */
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 400px;
  padding-left: 20px;
  padding-bottom: 24px;
  border-left: 1px solid #111D2F;
}

.subBox1 {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 32px !important
}

.apiBox1 {
  display: flex !important;
  column-gap: 32px !important;
  width: 100% !important;
  margin-top: 10px !important
}

.box {
  display: flex;
}

.fields {
  width: 280px;
  background-color: #111D2F;
  border-radius: 8px;
  height: 36px;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
}

.fields1 {
  width: 300px;
  background-color: #111D2F;
  border-radius: 8px;
  height: 36px;
  display: flex;
  padding: 12px;
  align-items: center;
}

.fieldText,
.fieldText1 {
  font-size: 14px !important;
  color: #FFFFFF !important
}

.fieldText1 {
  color: #469CFF !important
}

.apiType {
  color: white !important;
  font-size: 16px !important;
  margin-left: 15px !important;
  font-family: cardiuma-regular !important
}

.expirtText {
  font-size: 14px;
  color: #E4DAD7;
  font-family: "cardiuma-regular";
  padding-bottom: 5px;
}

.expiryText {
  font-size: 14px !important;
  color: #E4DAD7;
  font-family: cardiuma-regular !important;
  padding-bottom: 5px;
  margin-top: 10px !important;
}


.apiName {
  font-size: 24px !important;
  color: #20BFFC;
  font-family: cardiuma-SemiBold !important;
}

.buyNewAPIGrid {
  position: fixed;
  bottom: 0;
  width: 425px;
  height: 75px;
  background-color: #111D2F;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .apiGrid {
    width: 1344px !important;
    background-color: #70B3FF1F !important;
    height: auto !important;
    border-radius: 16px !important;
    margin-top: 12px !important;
    margin-bottom: 24px !important;
    padding: 24px 24px 0px 24px !important;
  }

.extendButton {
  color: #FCE77A !important;
  background: transparent;
  border-radius: 24px !important;
  border: 1px solid #FCE77A !important;
  text-transform: capitalize !important;
  width: 82px;
  height: 36px;
  margin-left: 15px !important;
  cursor: pointer;
}

.activeButton,
.expiredButton {
  color: #50D24A !important;
  width: 106px;
  height: 36px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #50D24A26 !important;
  /* Semi-transparent green */
  border-radius: 30px !important;
  margin-left: 15px !important;

  text-transform: none !important;
}

.expiredButton {
  color: #FF4524 !important;
  border: 1px solid #FF4524 !important;
  background-color: transparent !important;
}

.text {
  font-size: 15px !important;
}

.deleteIcon {
  display: inline;
}


.container1 {
  padding: 0px 20px 0px 20px;
  height: 90vh;
  flex-direction: column !important;
  display: flex;
  justify-content: center
}

.loginHeading,
.loginText,
.loginSubHeading {
  font-weight: bold;
  text-align: center;
  font-family: "cardiuma-regular";
  font-size: 28px !important;
}

.loginHeading {
  font-size: 44px !important;
  margin-bottom: 40px;
}

.loginSubHeading {
  font-size: 20px !important;
  color: #D8D7E4;
}

.errText {
  color: red !important;
  font-size: 14px !important;
  font-family: cardiuma-regular
}

.apiListGrid {
  width: 1344px !important;
  display: flex !important;
  justify-content: flex-end !important;
}

@media screen and (max-width: 1400px) {
  .apiListGrid {
    width: 100% !important;
  }
  .apiGrid{
    width: 100% !important;
  }
}

/*media queries*/
@media screen and (max-width: 1300px) {
  .box1 {
    width: 60%;
    padding-right: 20px;
    margin-top: 20px
  }

  .subBox1 {
    flex-direction: column !important;
  }




  .box2 {
    align-items: center;
    width: 40% !important;
    /* justify-content : center; */
    padding-top: 25px !important;
  }
}


@media screen and (max-width: 1110px) {
  .box1 {
    width: 50% !important;
  }

  .box2 {
    width: 50% !important;
  }

  .apiGrid{
    width: 700px !important;
  }

  .apiListGrid{
    width: 700px !important;
  }

  .apiBox1 {
    display: flex !important;
    flex-direction: column !important;
    column-gap: 32px !important;
    width: 100% !important;
    margin-top: 10px !important
  }

  .expirtText {
    font-size: 20px;
    color: #E4DAD7;
  }

  .apiName {
    font-size: 20px !important;
    color: #20BFFC;
    font-family: cardiuma-SemiBold !important;
  }





}

@media screen and (max-width: 460px) {
  .textField {
    width: 100% !important;
  }

  .nextButton {
    width: 100% !important;
  }
}

@media screen and (max-width: 470px) {
  .extendButton {
    margin-left: 0px !important;
  }
}



@media screen and (max-width: 750px) {
  .box {
    flex-direction: column !important;
  }

  .apiListGrid { 
    width: 425px !important;
  }

  .box1 {
    width: 100% !important;
  }

  .apiGrid{
    width: 425px !important;
  }

  .box2 {
    /* display : flex; */
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100% !important;
    padding-left: 0px;
    padding-bottom: 24px;
    border-left: none;
    padding-top: 0px !important;
    border-top: 2px solid #121826 !important;
  }

  .fields {
    width: 377px;
  }

  .fields1 {
    width: 377px;
  }

  .apiType {
    color: #20BFFC !important;
    font-size: 16px !important;
    margin-left: 15px !important;
    font-family: cardiuma-M !important
  }

  .extendButton {
    width: 60px;
    height: 25px;
  }

  .activeButton {
    width: 75px;
    height: 25px;
    margin-left: 10px !important;
  }

  .expiredButton {
    width: 75px;
    height: 25px;
    margin-left: 10px !important;
  }

  .text {
    font-size: 12px !important;
  }

  .deleteIcon {
    height: 25px;
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 392px;
  height: 190px;
  background-color: var(--background-paper, #fff);
  border: 2px solid #000;
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.modal1 {
  position: fixed;
  width: 80%;
  height: 190px;
  background-color: var(--background-paper, #fff);
  border: 2px solid #000;
  border-radius: 12px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 16px;
  bottom: 0;
  border-radius: "12px 12px 0px 0px";
}

.modalCancelButton,.modalOKButton {
  width: 166px;
  border-radius: 24px !important;
  border: 1px solid #F3D45E !important;
  color: #000000;
  text-transform: capitalize !important;
}

.modalOKButton {
  background-color:  #F3D45E !important;
}

  .deleteAPIText {
    font-size: 18px !important;
    width: 290px !important;
    text-align: center !important;
    margin-top: 20px !important;
    font-family: cardiuma-M !important;
}

.buyNewAPIButton {
    font-size: 15px;
    color: #FCE77A !important;
    background: transparent;
    border-radius: 24px !important;
    border: 1px solid #FCE77A !important;
    text-transform: capitalize !important;
    width: 361px;
    height: 36px;
}




@media screen and (max-width: 1200px) {
  .apipopUp {
    background-size: cover;
    width: 800px;
    height: 150px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

@media screen and (max-width: 1000px) {

  .pricingBox {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin-top: 22px !important;
    row-gap: 24px !important;
    flex-direction: column !important;
    align-items: center;

  }

  .priceBox {

    height: 248px;
    border-radius: 16px;
    padding: 24px;
    height: 220px !important;
  }

  .priceBoxTitle {
    font-size: 35px !important;
    color: #E4DAD7;
  }

  .apipopUp {
    width: 650px !important;
  }




}


@media screen and (max-width: 800px) {

  .createApplication {
    width: 100%;
    flex-direction: column !important;
    align-items: center !important;
    row-gap: 20px !important;
    margin-top: 22px;
    column-gap: 24px;
  }

  .generateButton {
    justify-content: center !important;
  }

  .apiTypeBox {
    width: 305px !important;
  }

  .apipopUp {
    width: 600px !important;
  }

}

@media (max-width: 720px) {
  .loginHeading {
    font-size: 35px !important;
  }

  .loginText {
    font-size: 24px !important;
  }

  .loginSubHeading {
    font-size: 20px !important;
    color: #D8D7E4;
  }
}


@media screen and (max-width: 650px) {

  .createApplication {
    width: 100%;
    flex-direction: column !important;
    align-items: center !important;
    row-gap: 20px !important;
    margin-top: 22px;
    column-gap: 24px;
  }

  .generateButton {
    justify-content: center !important;
  }

  .apiTypeBox {
    width: 305px !important;
  }

  .apipopUp {
    width: 480px !important;
  }

}

@media screen and (max-width: 500px) {

  .apiListGrid {
    width: 100% !important;
  }

  .buyNewAPIGrid {
    width: 92vw;
  }

  .priceBox {
    width: 300px !important;
    height: auto !important;
    border-radius: 16px;
    padding: 24px;

  }

  .modalCancelButton {
    width: 125px;
  }

  .apiGrid{
    width: 100% !important;
  }

  .deleteAPIText {
    font-size: 15px !important;
}

  .modalOKButton {
    width: 125px;
  }

  .priceBoxTitle {
    font-size: 35px !important;
    color: #E4DAD7;
  }

  .subscribeButton {
    width: 250px !important;


  }

  .apipopUp {
    width: 100% !important;
  }

  .box1 {
    padding-right: 0px;
  }

  .fields {
    width: 100% !important;
  }

  .fields1 {
    width: 100% !important;
  }

}

@media screen and (max-width: 440px) {
  .buyNewAPIGrid {
    width: 91vw;
  }
}

@media screen and (max-width: 382px) {
  .buyNewAPIGrid {
    width: 89vw;
  }
}

@media screen and (max-width: 350px) {
  .fieldText {
    font-size: 12px !important;
    color: #FFFFFF !important
  }

  .fieldText1 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 350px) {
  .fieldText {
    font-size: 12px !important;
    color: #FFFFFF !important
  }

  .fieldText1 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 350px) {
  .fieldText {
    font-size: 12px !important;
    color: #FFFFFF !important
  }

  .fieldText1 {
    font-size: 12px !important;
  }
}