.ourProductsText , .subText{
    color: #FFFFFF !important;
    font-size: 20px !important;
    text-align: center !important;
    font-family: 'cardiuma-regular' !important;
}


.parentGrid {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    height: 100vh !important;
    width: 100% !important;
    background-size: cover !important;
    overflow-y : auto !important;
    background-repeat: no-repeat !important;
}

.flex {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}

.headerGrid {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    padding-top: 100px !important;
}

.mainGrid {
    width: 1440px;
    display: flex;
    justify-content: center;
}

.mobileGrid {
   width: 100% !important;
}


.subText{
    font-size: 32px !important;
    font-weight: 700 !important;
    padding: 0px 20px 0px 20px !important;
}

.subscribeButton {
    font-family: 'cardiuma-regular' !important;
    text-transform: none !important;
    height: 52px;
    width: 292px;
    border-radius: 26px !important;
    border: 1px solid black !important;
    margin-top: 34px !important;
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: 700 !important;
}

.subscribeButton:hover {
    background-color: rgba(243, 212, 94, 1) !important;
    border: none !important;
}

.subTitle {
    width: 322px !important;
    font-family: 'cardiuma-regular' !important;
    text-align: center !important;
    font-size: 20px !important;
    margin-top: 20px !important;
}

.image {
    height: 76px;
    width: 76px;
    margin-top: 33px;
}

.title {
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    color: #19A1E9 !important;
}


.amount {
    font-family: 'cardiuma-SemiBold' !important;
    color: #000000;
    font-size: 24px !important;
    /* font-weight: 700; */
    margin-top: 20px !important;
}

.year {
    font-family: 'cardiuma-regular' !important;
    font-size: 16px;
    font-weight: 100;
    margin-top: 16px;
}

.card {
    background-color: rgba(255, 255, 255);
    width: 322px !important;
    height: 406px;
    margin-top: 72px;
    border-radius: 24px;
    margin-right: 16px;
    margin-left: 16px;
    position:  relative !important;
}



@media (min-width: 1460px) and (max-height:680px) {
 
    .card {
        margin-top: 50px !important;
        height: 375px !important;
    }
    .subscribeButton {
        margin-top: 35px !important;
    }
    .title{
        margin-top: 4px !important;
    }
    .amount {
        margin-top: 12px !important;
    }
    .subTitle {
        margin-top: 10px !important;
    }
    .headerGrid {
        padding-top: 85px !important;
    }

    
    
}

@media (max-width: 1460px) and (max-height: 680px) {
    .card {
        margin-top: 20px !important;
        height: 375px !important;
    }
    .subscribeButton {
        margin-top: 16px !important;
    }
    .title{
        margin-top: 4px !important;
    }
    .amount {
        margin-top: 12px !important;
    }
    .subTitle {
        margin-top: 10px !important;
    }
    .headerGrid {
        padding-top: 85px !important;
    }
}


@media (max-width : 1460px) {
    .card {
        margin-top: 16px;
    }
    .mainGrid {
        width: 100% !important;
    }
}

@media (max-width:800px) {
    .subText{
        font-size: 22px !important;
    }
    .card {
        width: 291px;
        height: 330px !important;
    }
    .subscribeButton {
        width: 261px;
        margin-top: 16px !important;
    }
    .title{
        font-size: 20px !important;
        margin-top: 4px !important;
    }
    .image {
        height: 57px !important;
        width: 57px !important;
        margin-top: 16px;
    }
    .amount {
        margin-top: 12px !important;
    }
    .subTitle {
        font-size: 20px;
        margin-top: 10px !important;
    }
    .mobileGrid {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;
     }
}

@media (max-width:750px) {
 
    .card {
        width: 60% !important;
        height: 330px !important;
    }
    
}

@media (max-width:600px) {
 
    .card {
        width: 320px !important;
        height: 330px !important;
    }

}

@media (max-width:400px) {
 
    .card {
        width: 300px !important;
        height: 330px !important;
    }
    
}





