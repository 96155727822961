/*full file added by dev-Ayush 20-02-2025*/

.mainLoginGrid {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    
    padding: 0px 120px 54px 120px;
}

.loginSubGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1440px;
    height: 100vh;
}

.textGrid {
    height: 100px;
}

.text {
    font-size: 62px !important;
    font-weight: 900 !important;
    color: #ffffff;
    font-family: cardiuma-Bold !important;
}

.loginGrid {
    height: 700px;
    width: 665px;
    padding-bottom: 72px;
    padding-top: 32px;
    border-radius: 24px;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    margin-left: 72px;
}

.divider {
    background: linear-gradient(90deg, #16198C 0%, #F3D45E 43.5%, #131991 100%);
    height: 1px;
    width: 577px;
    margin-top: 40px !important;
}

.productsGrid {
    border-radius: 8px;
    background-color: #FBFDFF;
    height: 62px !important;
    min-height: 62px !important;
    width: 400px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.productsSubGrid{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.radioButton {
    background-color: #17164F;
    height: 24px;
    width: 24px;
    border-radius: 100px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F3D45E;
}

.subRadioButton {
    background-color: #F3D45E;
    height: 12px;
    width: 12px;
    border-radius: 100px;
}

.loginButton {
    border-radius: 30px;
    background-color: #F3D45E;
    height: 48px;
    width: 400px;
    margin-top: 32px;
    padding: 12px 0px 15.5px 0px;
    cursor: pointer;
}


.bullforceLogo {
    height: 50px;
    width: 330px;
}

.appBar {
    height : 60px;
    width : 100%;
    background-color : #191146
}

.appBarImage {
    height : 20px;
    width : 135px;
    padding : 16px
}

.loginText {
    font-size : 32px !important ; 
    color : #BCD171 !important ; 
    margin : 18px 0px -4px 0px !important;
    font-family: cardiuma-regular !important;
}

.dontHaveAccount {
    color : #FFFFFF !important ; 
    margin-top : 31px !important; 
    font-size : 18px !important;
    font-family: cardiuma-regular !important;
}

@media (max-height : 800px) and (min-width : 450px)  {
    .loginGrid {
        height: 650px !important;
        width: 615px !important;
        padding-bottom: 30px !important;
    }
    .divider {
        margin-top: 20px !important;
    }
    .bullforceLogo {
        height: 35px;
        width: 225px;
    }
    .loginText {
        font-size : 24px !important ; 
        
    }
}

@media (max-height : 800px) and (max-width : 800px)  {
    .loginGrid {
        width: 600px !important;
    }
}

@media (max-height : 800px) and (max-width : 680px)  {
    .loginGrid {
        width: 525px !important;
    }
}

@media (max-height : 800px) and (max-width : 600px)  {
    .loginGrid {
        width: 100% !important;
    }
}






@media (max-height : 650px) and (max-width : 680px)  {
    .loginGrid {
        width: 100% !important;
    }
}

@media (max-width: 1460px) {
    .text {
        font-size: 50px !important;
    }
}

@media (max-width: 1360px) {
    .text {
        font-size: 40px !important;
    }
}

@media (max-width: 1318px) {
    .mainLoginGrid {
        padding: 0px 100px 54px 100px !important;
    }
}

@media (max-width: 1279px) {
    .textGrid {
        display: none;
    }
    .loginSubGrid {
        justify-content: center;
    }
    .loginGrid {
        margin-left: 0px;
    }
}

@media (max-width: 800px) {

    .loginGrid {
        width: 600px;
    }
    .divider {
        width: 512px;
    }
}

@media (max-width: 680px) {

    .loginGrid {
        width: 525px;
    }
    .divider {
        width: 425px;
    }
}


@media (max-width: 600px) {

    .loginGrid {
        width: 100%
    }
    .divider {
        display: none;
    }
    .bullforceLogo {
        display: none
    }
    .mainLoginGrid {
        padding: 0px !important;
    }
    .appBar {
        height : "20px";
        width : "135px";
        padding : "16px"
    }
    .loginText {
        margin-top: -10px !important;
        margin-bottom: 10px !important;
    }
}





@media (min-width: 600px) {

    .appBar {
       display: none;
    }
}

@media (max-width : 450px) {
    .productsGrid {
        width: 100% !important;
    }
   
    .loginGrid{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .loginButton{
        width: 100% !important;
    }
}

@media (max-width : 450px) {
    .productsGrid {
        width: 100% !important;
    }
    .loginGrid{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .loginButton{
        width: 100% !important;
    }
}

@media (max-width : 330px) {
    .loginGrid{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (max-width : 360px) {

    .dontHaveAccount {
        font-size : 16px !important;
    }
}



@media (max-height : 750px) {
    .loginGrid {
        height: 575px !important;
        width: 550px !important;
        padding-bottom: 30px !important;
    }
    .divider {
        margin-top: 20px !important;
        width: 400px;
    }
    .bullforceLogo {
        height: 30px;
        width: 225px;
    }
    .loginText {
        margin-top: 15px !important;        
    }
    .productsGrid {
        height: 50px !important;
        min-height: 50px !important;
        width: 350px !important;
    }
    .loginButton{
        width: 350px !important;
        margin-top: 30px ;
    }
    .dontHaveAccount {
        margin-top : 25px !important; 
    }
}

@media (max-height : 750px) and (max-width : 600px)  {
    .loginGrid {
        width: 100% !important;
    }
}

@media (max-width: 600px) and (max-height : 750px) {
    .loginText {
        margin-top: -20px !important;
        margin-bottom: 10px !important;
    }

}

@media (max-height : 700px) {
    .loginGrid {
        height: 560px !important;
        width: 530px !important;
        padding-bottom: 30px !important;
        padding-top: 40px !important;
    }
    .divider {
        margin-top: 20px !important;
        width: 400px;
    }
    .loginText {
        margin-top: 10px !important;        
    }
    .productsGrid {
        height: 50px !important;
        min-height: 50px !important;
        width: 350px !important;
    }
    .loginButton{
        width: 350px !important;
        margin-top: 30px ;
    }
    .dontHaveAccount {
        margin-top : 20px !important; 
    }
}


@media (max-height : 700px) and (max-width : 600px)  {
    .loginGrid {
        width: 100% !important;
    }
}

@media (max-width: 600px) and (max-height : 700px) {
    .loginText {
        margin-top: -20px !important;
        margin-bottom: 10px !important;
    }
}

@media (max-height : 650px) {
    .loginGrid {
        height: 525px !important;
        width: 525px !important;
        padding-bottom: 30px !important;
        padding-top: 25px !important;
    }
    .divider {
        margin-top: 20px !important;
        width: 400px;
    }
    .loginText {
        margin-top: 10px !important;        
    }
    .productsGrid {
        height: 50px !important;
        min-height: 50px !important;
        width: 350px !important;
    }
    .loginButton{
        width: 350px !important;
        margin-top: 15px ;
    }
    .dontHaveAccount {
        margin-top : 20px !important; 
    }
}

@media (max-height : 650px) and (max-width : 600px)  {
    .loginGrid {
        width: 100% !important;
    }
}


@media (max-width: 600px) and (max-height : 650px) {
    .loginText {
        margin-top: -10px !important;
        margin-bottom: 10px !important;
    }
   
    
}

@media (max-height : 800px) and (max-width : 450px)  {
    .loginGrid {
        height: 650px !important;
        width: 100% !important;
        padding-bottom: 30px !important;
    }
    .productsGrid {
        width: 100% !important;
    }
}

@media (max-height : 750px) and (max-width : 450px)  {
    .loginGrid {
        width: 100% !important;
        height: 100% !important;
    }
    .loginButton { 
        width: 100%  !important;
    }
    .productsGrid {
        width: 100% !important;
    }
    .loginText {
        margin-top: 40px !important;
        font-size: 26px !important;
    }
}

@media (max-height : 650px) and (max-width : 450px)  {
    .loginText {
        margin-top: 30px !important;
    }
}

@media (max-height : 600px) and (max-width : 450px)  {
    .loginText {
        margin-top: 20px !important;
    }
}





